import { addEvent } from "./_events";
export const SUCCESS_MESSAGE_DISPLAY_TIMER = 3000;
const addToCartButtonStyle = Object.freeze({
  Default: "mc-button--solid",
  Success: "mc-button--bordered",
  Error: "mc-button--bordered-danger"
});
const addToCartButtonImage = Object.freeze({
  Default: "#Product_Basket_Add_48px",
  Success: "#Navigation_Notification_Available_32px",
  Error: "#Navigation_Control_Cross_32px"
});
function addToCart(form, lightMode, eventTarget, self) {
  eventTarget.preventDefault();
  const listProducts = [];
  const productIdentifier = form.querySelector('input[name="reflm"]').value;
  const requestQuantity = form.querySelector('input[name="quantity"]') ? form.querySelector('input[name="quantity"]').value : 1;
  listProducts.push({
    quantity: requestQuantity,
    reflm: productIdentifier,
    offerId: form.querySelector('input[name="offerId"]').value,
    contextCode: form.querySelector('input[name="contextCode"]').value,
    atcButtonLocation: form.querySelector('input[name="atcButtonLocation"]').value,
    ...(lightMode && {
      lightMode,
      atcForm: form
    })
  });
  self.cartUpdate({
    listProducts
  });
}
function handleSuccessMessage(form) {
  const svgElement = form.querySelector(".js-thumbnail-add-to-cart-light-form svg use");
  const buttonAddToCart = form.querySelector(".js-thumbnail-add-to-cart-light-form button");
  const successMessage = form.querySelector(".product-thumbnails-add-to-cart-msg__success");
  displaySuccessMessage(successMessage, svgElement, buttonAddToCart);
  setTimeout(() => hideSuccessMessage(successMessage, svgElement, buttonAddToCart), SUCCESS_MESSAGE_DISPLAY_TIMER);
}
function displaySuccessMessage(successMessage, svgElement, buttonAddToCart) {
  successMessage.classList.remove("kl-hidden");
  if (svgElement) {
    svgElement.setAttribute("href", addToCartButtonImage.Success);
    buttonAddToCart.classList.replace(addToCartButtonStyle.Default, addToCartButtonStyle.Success);
  }
}
function hideSuccessMessage(successMessage, svgElement, buttonAddToCart) {
  successMessage.classList.add("kl-hidden");
  if (svgElement) {
    svgElement.setAttribute("href", addToCartButtonImage.Default);
    buttonAddToCart.classList.replace(addToCartButtonStyle.Success, addToCartButtonStyle.Default);
  }
}
function handleErrorMessage(form) {
  const errorMessage = document.querySelector(".product-thumbnails-atc-notification-fail");
  const buttonCloseNotification = document.querySelector(".product-thumbnails-atc-fail__close");
  const formAddToCart = form.querySelector(".js-cart-add");
  errorMessage.classList.remove("kl-hidden");
  formAddToCart.classList.add("kl-hidden");
  buttonCloseNotification.addEventListener("click", () => {
    errorMessage.classList.add("kl-hidden");
  });
}
class ProductThumbnail extends lm.Composant {
  constructor(id) {
    super(id);
    document.addEventListener("cartSuccess", event => {
      if (event?.detail?.product?.atcForm) {
        handleSuccessMessage(event.detail.product.atcForm);
      }
    });
    document.addEventListener("cartError", event => {
      if (event?.detail?.product?.atcForm) {
        handleErrorMessage(event.detail.product.atcForm);
      }
    });
    addEvent(document, "submit", e => addToCart(e.matchingTarget, false, e, this), ".js-thumbnail-add-to-cart-form", this);
    addEvent(document, "submit", e => addToCart(e.matchingTarget, true, e, this), ".js-thumbnail-add-to-cart-light-form", this);
    addEvent(document, "click", () => {
      window.dispatchEvent(new CustomEvent("openStoreContextLayerInSearchMode"));
    }, ".js-th-display-availabilities", this);
    addEvent(document, "click", e => {
      document.dispatchEvent(new CustomEvent("arm-popin-dsa-open", {
        detail: e.matchingTarget.getAttribute("data-io")
      }));
    }, ".arm-thumbnail-open-modal", this);
  }
}
lm.DOMReady(function () {
  new ProductThumbnail("product-thumbnail");
});
export default ProductThumbnail;