class Events {
  constructor() {
    this.eventsConfigurations = [];
    this.isDOMReady = false;
    this._handleDocumentState();
  }
  static dispatchEvent(eventName, domElement, rootEl) {
    rootEl = rootEl || document;
    if (eventName) {
      let event = new CustomEvent(eventName);
      event.customTarget = domElement;
      rootEl.dispatchEvent(event);
    }
  }

  /**
   * Remove the event handler for the given parameters if it exists
   * @param rootElement
   * @param eventName
   * @param callBackFunction
   * @param querySelector
   */
  removeEvent(rootElement, eventName, callBackFunction, querySelector) {
    if (rootElement && typeof eventName && typeof callBackFunction === "function") {
      let properties = this._getEventPropertiesForElement(rootElement);
      let events = properties.events;
      if (events[eventName]) {
        events[eventName] = events[eventName] || [];
        for (var i = events[eventName].length - 1; i >= 0; i--) {
          let el = events[eventName][i];
          if (el.qs === querySelector && el.fn === callBackFunction) {
            events[eventName].splice(i, 1);
          }
        }
        if (events[eventName].length === 0) {
          rootElement.removeEventListener(eventName, properties.fn);
          delete events[eventName];
        }
      }
    }
  }

  /**
   * Add a delegated event on the given rootElement
   * @param rootElement
   * @param eventName
   * @param callBackFunction
   * @param querySelector
   */
  addEvent(rootElement, eventName, callBackFunction, querySelector, objParent) {
    if (rootElement && eventName && typeof callBackFunction === "function") {
      if (this.isDOMReady && eventName == "DOMContentLoaded") {
        callBackFunction.call(objParent, this.DOMContentLoadedEvent);
        return;
      }
      let properties = this._getEventPropertiesForElement(rootElement);
      let events = properties.events;
      if (!events[eventName]) {
        rootElement.addEventListener(eventName, properties.fn);
      }
      events[eventName] = events[eventName] || [];
      let isAlreadyBound = false;
      for (var i = 0; i < events[eventName].length; i++) {
        let el = events[eventName][i];
        if (el.qs === querySelector && el.fn === callBackFunction) {
          isAlreadyBound = true;
        }
      }
      if (!isAlreadyBound) {
        events[eventName].push({
          fn: callBackFunction,
          qs: querySelector,
          op: objParent
        });
      }
    }
  }
  _saveDomContentLoadedState(e) {
    this.DOMContentLoadedEvent = e;
    this.isDOMReady = true;
  }

  /**
   * Get the next testable dom element for the current element
   * @param event
   * @param el
   * @param index
   * @returns Next DOM Element on which the event handler might be applied
   */
  _getNextDomElement(event, el, index) {
    if (event.customTarget) {
      let targetEl = event.customTarget;
      delete event.customTarget;
      return targetEl;
    }
    if (event.path && event.path.length > index) {
      return event.path[index];
    }
    if (el == null && index == 0) {
      return event.target;
    }
    if (el != null) {
      return el.parentElement;
    }
    return null;
  }

  /**
   * Get All Declared event handlers for the given root element
   * @param domElement
   * @returns {
   domElement: domElement,
  events: event handlers list,
  fn: triggerMethod
  }
   */
  _getEventPropertiesForElement(domElement) {
    for (var i = 0; i < this.eventsConfigurations.length; i++) {
      if (this.eventsConfigurations[i].domElement === domElement) {
        return this.eventsConfigurations[i];
      }
    }
    let newEventProperties = {
      domElement: domElement,
      events: {},
      fn: e => {
        this._evaluateDelegatedEvents(domElement, e);
      }
    };
    this.eventsConfigurations.push(newEventProperties);
    return newEventProperties;
  }

  /**
   * Evaluate the differents event handlers for the given rootElement and the real event.
   * @param rootElement
   * @param e
   */
  _evaluateDelegatedEvents(rootElement, e) {
    e.isPropagationStopped = false;
    e.stopPropagationInternal = e.stopPropagation;
    e.stopPropagation = function () {
      if (!e.isPropagationStopped) {
        e.isPropagationStopped = true;
        e.stopPropagationInternal();
      }
    };
    let events = this._getEventPropertiesForElement(rootElement).events;
    events[e.type] = events[e.type] || [];
    let listeners = events[e.type].concat();
    let i = 0;
    let el = this._getNextDomElement(e, null, i);
    while (el != null) {
      for (var j = 0; j < listeners.length; j++) {
        if (listeners[j] != null && (listeners[j].qs == null || el != null && typeof el.matches === "function" && el.matches(listeners[j].qs))) {
          e.matchingTarget = el;
          listeners[j].fn.call(listeners[j].op, e);
          listeners[j] = null;
        }
        if (e.isPropagationStopped == true) {
          break;
        }
      }
      if (e.isPropagationStopped == true || el.tagName === "BODY") {
        break;
      }
      i++;
      el = this._getNextDomElement(e, el, i);
    }
  }
  _handleDocumentState() {
    if (document.readyState === "complete" || document.readyState === "interactive") {
      this._saveDomContentLoadedState(new CustomEvent("DOMContentLoaded"));
    } else {
      this.addEvent(document, "DOMContentLoaded", this._saveDomContentLoadedState, null, this);
    }
  }
}
let eventsInstance = new Events();
window.THUMBNAIL = window.THUMBNAIL || {};
window.THUMBNAIL.events = {
  addEvent: (rootElement, eventName, callBackFunction, querySelector, objParent) => {
    eventsInstance.addEvent(rootElement, eventName, callBackFunction, querySelector, objParent);
  },
  removeEvent: (rootElement, eventName, callBackFunction, querySelector) => {
    eventsInstance.removeEvent(rootElement, eventName, callBackFunction, querySelector);
  },
  dispatchEvent: Events.dispatchEvent
};
module.exports = window.THUMBNAIL.events;